<template>
  <v-card
    elevation="0"
    color="white"
    height="400"
    width="300"
    class="transition-fast-in-fast-out"
    tile
  >
    <v-card-title class="text-truncate">
      <v-avatar size="100"
        ><v-img
          src="@/assets/1487716598-screen-shot-2017-02-21-at-53623-pm.png"
        ></v-img
      ></v-avatar>
      <h2 class="ml-2">George</h2>
    </v-card-title>
    <v-card-text>Graphic designer</v-card-text>
    <v-card-text>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sequi eligendi
      harum facere officia cumque illum, quidem facilis ullam sapiente minima
      quis culpa. Totam quis dicta numquam at expedita debitis nulla.
    </v-card-text>
    <v-card-actions>
      <v-btn flat icon color="primary">
        <v-icon>mdi-twitter</v-icon>
      </v-btn>
      <v-btn flat icon color="primary">
        <v-icon>mdi-instagram</v-icon>
      </v-btn>
      <v-btn flat icon color="primary">
        <v-icon>mdi-facebook</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {};
</script>

<style>
</style>