<template>
  <div class="home">
    <v-card color="transparent" elevation="0">
      <v-card-text class="mx-auto mt-15">
        <h1 class="text-center title">
          <span
            :style="
              $vuetify.breakpoint.xs
                ? 'font-size: 75px; '
                : 'font-size: 100px; '
            "
            class="title-part line-1"
            >eleganse</span
          >
        </h1>
        <h1
          class="text-center pa-10 mt-5"
          :style="
            $vuetify.breakpoint.xs
              ? 'font-size: 25px; font-weight: 200'
              : 'font-size: 50px; font-weight: 200'
          "
        >
          elegans för alla
        </h1>
      </v-card-text>
    </v-card>

    <v-card max-width="1200" elevation="0" color="transparent" class="mx-auto">
      <v-layout wrap :justify-center="$vuetify.breakpoint.xs" :justify-space-between="!$vuetify.breakpoint.xs"   >

          <team-card class="mt-2"></team-card>
          <team-card class="mt-2"></team-card>
          <team-card class="mt-2"></team-card>


      </v-layout>


      <contact-form class="mt-10"></contact-form>
    </v-card>
  </div>
</template>

<script>
import ContactForm from "../components/ContactForm.vue";
import TeamCard from "../components/TeamCard.vue";
// @ is an alias to /src

export default {
  name: "Home",
  components: { ContactForm, TeamCard },
  data: () => {},
};
</script>
