import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#1D1F1C',
          secondary: '#DADADA',
          accent: '#FFFFD9',
          error: '#b71c1c',
          background: "#DADADA"
        },
      },
    },
  })


export default vuetify;