<template>
  <v-app style="background-image: linear-gradient(to right, white, #d4d4d4)">
    <v-app-bar
      elevation="0"
      style="background-image: linear-gradient(to right, white, #d4d4d4)"
      app
    >
      <v-app-bar
        class="mx-auto"
        app
        max-width="1200"
        color="transparent"
        elevation="0"
      >
        <v-app-bar-nav-icon
          v-if="isMobile"
          class="mr-2"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
        <v-card class="pa-0 ma-0 transparent" elevation="0" max-width="250">
          <h1>eleganse</h1>
        </v-card>

        <v-spacer></v-spacer>
        <v-btn v-if="!isMobile" class="" tile text color="primary"
          >Vårt team</v-btn
        >
        <v-btn v-if="!isMobile" class="" tile text color="primary"
          >Bakgrund</v-btn
        >
        <v-btn v-if="!isMobile" class="" tile text color="primary"
          >Portfolio</v-btn
        >

        <v-spacer></v-spacer>
        <v-card class="pa-0 ma-0 transparent" elevation="0" max-width="250">
          <v-btn tile outlined color="primary">Kontakta</v-btn>
        </v-card>
      </v-app-bar>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="primary">
          <v-btn tile class="mt-2" text width="100%" color="primary"
            >Vårt team</v-btn
          >
          <v-btn tile class="mt-2" text width="100%" color="primary"
            >Bakgrund</v-btn
          >
          <v-btn tile class="mt-2" text width="100%" color="primary"
            >Portfolio</v-btn
          >
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view />
      <v-footer class="mt-15" dark padless>
        <v-card flat tile class="primary lighten-1 white--secondary text-center">
          <v-card-text>
            <v-btn
              v-for="icon in icons"
              :key="icon"
              class="mx-4 white--text"
              icon
            >
              <v-icon size="24px">
                {{ icon }}
              </v-icon>
            </v-btn>
          </v-card-text>

          <v-card-text class="white--text pt-0">
            Phasellus feugiat arcu sapien, et iaculis ipsum elementum sit amet.
            Mauris cursus commodo interdum. Praesent ut risus eget metus luctus
            accumsan id ultrices nunc. Sed at orci sed massa consectetur
            dignissim a sit amet dui. Duis commodo vitae velit et faucibus.
            Morbi vehicula lacinia malesuada. Nulla placerat augue vel ipsum
            ultrices, cursus iaculis dui sollicitudin. Vestibulum eu ipsum vel
            diam elementum tempor vel ut orci. Orci varius natoque penatibus et
            magnis dis parturient montes, nascetur ridiculus mus.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="white--text">
            {{ new Date().getFullYear() }} — <strong>eleganse</strong>
          </v-card-text>
        </v-card>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({ isMobile: false, drawer: false, group: null }),

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
      window.isMobile = window.innerWidth < 600;
    },
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
};
</script>

<style>
p {
  color: #1d1f1c;
}
h1 {
  color: #1d1f1c;
}
</style>
