<template>
  <v-card tile class="pa-5" elevation="0" color="white">
    <v-card-text>
      <h2 class="primary--text">Kontakta</h2>
    </v-card-text>

    <v-card-text>
      <v-form>
        <v-row>
          <v-col
            ><v-text-field
              color="primary"
              name="name"
              label="Ditt namn*"
              id="id"
            ></v-text-field
          ></v-col>
          <v-col
            ><v-text-field
              color="primary"
              name="email"
              label="E-post*"
              id="id"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              :items="items"
              v-model="typ"
              label="Bästa sätt att kommunicera*"
            ></v-select>
          </v-col>
          <v-col
            ><v-text-field
              color="primary"
              name="email"
              label="Telefon"
              id="id"
            ></v-text-field
          ></v-col>
        </v-row>

        <v-textarea
          color="primary"
          name="input-7-1"
          label="Meddelande*"
        ></v-textarea>
        <v-btn tile outlined color="primary">Skicka</v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => {
    return {
      items: ["E-post", "Digitalt möte"],
    };
  },
};
</script>

<style>
</style>